
import Vue from "vue";
export default Vue.extend({
  name: "component-place-panel",
  props: {
    title: { type: String, required: true },
  },
  data(): { intersectionObserver: IntersectionObserver } {
    return {
      intersectionObserver: new window.IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.$gtag.event("Intersect Forecast Graph");
        }
      }),
    };
  },
  mounted() {
    setTimeout(() => {
      this.intersectionObserver.observe(this.$el);
    }, 1000);
  },
  beforeDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(this.$el);
    }
  },
});
