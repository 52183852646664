
import Vue from "vue";

export default Vue.extend({
  name: "component-radio-button",
  model: {
    prop: "checked",
    event: "input",
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    checked: {
      type: [String, Number, Boolean, Object],
      required: false,
      default: undefined,
    },
    value: {
      type: [String, Number, Boolean, Object],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.value);
    },
  },
});
