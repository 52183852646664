
import Vue from "vue";
import HeaderForecast from "@/components/place/header-forecast.vue";

export default Vue.extend({
  name: "the-header",
  components: {
    HeaderForecast,
  },
  props: {
    label: { type: String, default: "" },
  },
  methods: {
    fixedDaysRow(payload: boolean) {
      this.$emit("fixed-days-row", payload);
    },
  },
});
