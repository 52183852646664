
import Vue from "vue";

import { Place as BackendPlace, Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import {
  shortAccessI18nKeyByPlaceNumber,
  prTitleI18nKeyByPlaceNumber,
  prTextI18nKeyByPlaceNumber,
  locationByPlaceIdHash,
} from "@/plugins/support";

import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";
import NaviButton from "@/components/place/navi-button.vue";
import LinkCopyButton from "@/components/place/link-copy-button.vue";
import LinkCopyToast from "@/components/place/link-copy-toast.vue";

type Equipment = BackendPlace.Equipment;
type HotSpringSettingValue = Http.HotSpringSettingValue;

export default Vue.extend({
  name: "panel-summary",
  components: {
    LabelSet,
    VacancyText,
    NaviButton,
    LinkCopyButton,
    LinkCopyToast,
  },
  props: {
    placeIdHash: { type: String, required: true },
    floor: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "-" },
    categoryList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    // vacancy-text props
    vacancyStatus: { type: String, required: false, default: "closed" },
    vacancyText: { type: String, required: false, default: "-" },
    // label-set props
    openingHoursList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    // feature props
    featureList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    // onsen props
    hotSpringOpeningHoursList: {
      type: Array as () => HotSpringSettingValue[],
      required: false,
      default: (): HotSpringSettingValue[] => [],
    },
    currentGender: { type: String, required: false, default: undefined },
    showForecast: { type: Boolean, default: false },
  },
  data() {
    return {
      linkCopied: false,
    };
  },
  computed: {
    showLabelSet(): boolean {
      return this.equipmentList.length > 0 || this.currentGender !== undefined || this.showSchedules;
    },
    showSchedules(): boolean {
      if (this.openingHoursList.length === 0 && this.hotSpringOpeningHoursList.length === 0) {
        return false;
      }

      const idListCsv: string | undefined = process.env.VUE_APP_PLACE_ID_LIST_SHOW_SCHEDULES;
      if (idListCsv === undefined) {
        return false;
      }

      const idList: string[] = idListCsv.split(",");
      return idList.includes(this.placeIdHash);
    },
    showFloor(): boolean {
      return false; // This is intended for ise only.
    },
    showCategoryList(): boolean {
      return this.categoryList.length > 0;
    },
    accessI18nKey(): string {
      return shortAccessI18nKeyByPlaceNumber(this.placeIdHash);
    },
    prTitleI18nKey(): string {
      return prTitleI18nKeyByPlaceNumber(this.placeIdHash);
    },
    prTextI18nKey(): string {
      return prTextI18nKeyByPlaceNumber(this.placeIdHash);
    },
    lat(): number {
      return locationByPlaceIdHash(this.placeIdHash).lat;
    },
    lng(): number {
      return locationByPlaceIdHash(this.placeIdHash).lng;
    },
  },
  methods: {
    onShowForecast() {
      this.$gtag.event("Show Forecast in detail");
    },
  },
});
