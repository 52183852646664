import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import { appStore } from "./store";
import "@/plugins/gtags";
import i18n from "@/plugins/i18n";
// lazy-load-image
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

Vue.config.productionTip = false;

new Vue({
    router,
    store: appStore,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
