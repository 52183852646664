import { fetchVacancyForecastByPlaceIdHashList, fetchWeeks } from "@/api/enterprise-vacan.adapter.api";
import { Http, Place, Vacancy } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import dayjs from "dayjs";
import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree, Store } from "vuex";

export type ViewType = "realtime" | "forecast";
export function isViewType(value: unknown): value is ViewType {
    if (typeof value !== "string") {
        return false;
    }

    return ["realtime", "forecast"].includes(value);
}

Vue.use(Vuex);

export interface AppState {
    placeList: Place.Place[];
    viewType: ViewType;
    selectedUnixtime?: number;

    weekdayList: {
        date: string;
        unixtime: number;
        isWorkday: boolean;
    }[];

    forecastDictionary: {
        [date: string]: {
            [placeIdHash: string]: Vacancy.VacancyOfHour[];
        };
    };
}

const state: AppState = {
    viewType: "realtime",
    placeList: [],
    selectedUnixtime: undefined,

    weekdayList: [],
    forecastDictionary: {},
};

const getters: GetterTree<AppState, AppState> = {
    getForecast(state): (placeIdHash: string) => Vacancy.VacancyOfHour[] | undefined {
        return (placeIdHash: string) => {
            if (!state.selectedUnixtime) {
                return;
            }

            const targetDate = dayjs.unix(state.selectedUnixtime).format("YYYYMMDD");
            return state.forecastDictionary[targetDate] ? state.forecastDictionary[targetDate][placeIdHash] : undefined;
        };
    },
    getForecastDictionary(state): { [placeIdHash: string]: Vacancy.VacancyOfHour[] } | undefined {
        if (!state.selectedUnixtime) {
            return;
        }

        const targetDate = dayjs.unix(state.selectedUnixtime).format("YYYYMMDD");
        return state.forecastDictionary[targetDate];
    },
    getIsToday(state): boolean {
        if (!state.selectedUnixtime || !state.weekdayList[0]) {
            return false;
        }

        return dayjs.unix(state.selectedUnixtime).isSame(dayjs.unix(state.weekdayList[0].unixtime), "date");
    },
};

const mutations: MutationTree<AppState> = {
    setViewType(state: AppState, payload: ViewType) {
        state.viewType = payload;
    },
    setAllPlaceList(state: AppState, payload: Place.Place[]) {
        state.placeList = payload;
    },
    updatePlaceList(state: AppState, payload: Place.Place) {
        const targetIndex = state.placeList.findIndex((place) => place.placeIdHash === payload.placeIdHash);

        state.placeList.splice(targetIndex, 1, payload);
    },
    setSelectedUnixtime(state: AppState, payload: number) {
        state.selectedUnixtime = payload;
    },

    setWeekdayList(state: AppState, payload: Http.ResponseWeekdayList) {
        state.weekdayList = payload.map((day) => ({ ...day, date: dayjs.unix(day.unixtime).format("YYYYMMDD") }));

        if (
            !state.selectedUnixtime ||
            (state.selectedUnixtime &&
                dayjs.unix(state.selectedUnixtime).isBefore(dayjs.unix(state.weekdayList[0].unixtime), "date"))
        ) {
            state.selectedUnixtime = state.weekdayList[0].unixtime;
        }
    },

    setForecast(state: AppState, payload: Http.ResponseForecast) {
        const targetDate = dayjs.unix(payload.unixtime).format("YYYYMMDD");
        state.forecastDictionary = {
            ...state.forecastDictionary,
            [targetDate]: state.forecastDictionary[targetDate] ?? {},
        };

        payload.placeForecastList.forEach((placeForecast) => {
            const placeIdHash = placeForecast.placeIdHash;
            state.forecastDictionary[targetDate][placeIdHash] = placeForecast.forecastList;
        });
    },
};

const actions: ActionTree<AppState, AppState> = {
    setPlaceList({ commit, dispatch }, payload: Place.Place[]) {
        commit("setAllPlaceList", payload);
        dispatch("fetchForecast");
    },
    updatePlace({ commit, dispatch }, payload: Place.Place) {
        commit("updatePlaceList", payload);
        dispatch("fetchForecast");
    },
    async fetchWeekdayList({ commit, dispatch, state }) {
        if (!!state.weekdayList[0] && dayjs.unix(state.weekdayList[0].unixtime).isSame(dayjs(), "date")) {
            return;
        }

        commit("setWeekdayList", await fetchWeeks());
        dispatch("fetchForecast");
    },
    async fetchForecast({ commit, state, rootState }) {
        for (const day of state.weekdayList) {
            const needUpdatePlaceList = rootState.placeList.filter(
                (place) =>
                    !state.forecastDictionary[day.date] || !state.forecastDictionary[day.date][place.placeIdHash],
            );

            if (needUpdatePlaceList.length > 0) {
                commit(
                    "setForecast",
                    await fetchVacancyForecastByPlaceIdHashList(
                        needUpdatePlaceList.map((place) => place.placeIdHash),
                        day.unixtime,
                    ),
                );
            }
        }
    },
};

export const appStore = new Store({
    state,
    getters,
    mutations,
    actions,
    strict: process.env.NODE_ENV !== "production",
});
