const placeNumberByHash = (hash: string): number => {
    switch (hash) {
        case "5r01Zdqn": // dev
        case "mrpyG7nl": // prod/stg
            return 1;
        case "qr7B7zY9":
        case "RnlV4o9E":
            return 2;
        case "grjq6zo9":
        case "L9Yg06n3":
            return 3;
        case "5X8kOMKn":
        case "oXOwNKXM":
            return 4;
        case "5Xkeg35r":
        case "Rna6m1XM":
            return 5;
        case "oXRd7e4r":
        case "4Xqgoqrj":
            return 6;
        case "Znxwkgbr":
        case "59eQbx9v":
            return 7;
        case "krbJM2er":
        case "L944yw94":
            return 8;
        case "Gnv5kdVr":
        case "vX2pvBrQ":
            return 9;
        case "arZ01G3X":
        case "k9o4DErg":
            return 10;
        default:
            return 1;
    }
};

export const locationByPlaceIdHash = (placeIdHash: string): { lat: number; lng: number } => {
    const placeNumber: number = placeNumberByHash(placeIdHash);
    switch (placeNumber) {
        case 1:
            return { lat: 34.45896164487187, lng: 136.72288360125066 };
        case 2:
            return { lat: 34.46246071495691, lng: 136.72286288 };
        case 3:
            return { lat: 34.46557395393336, lng: 136.7229811737556 };
        case 4:
            return { lat: 34.49074172619162, lng: 136.7093450154323 };
        case 5:
            return { lat: 34.488070896531184, lng: 136.7067211026453 };
        case 6:
            return { lat: 34.50918990910366, lng: 136.7878293980637 };
        case 7:
            return { lat: 34.45976532291694, lng: 136.7910072713772 };
        case 8:
            return { lat: 34.496879012950345, lng: 136.71754619206513 };
        case 9:
            return { lat: 34.50829607088611, lng: 136.78454609806397 };
        case 10:
            return { lat: 34.492642, lng: 136.686028 };
        default:
            return { lat: 34.45896164487187, lng: 136.72288360125066 };
    }
};

export const shortAccessI18nKeyByPlaceNumber = (placeIdHash: string): string => {
    const placeNumber: number = placeNumberByHash(placeIdHash);
    return `data.${placeNumber}.shortAccess`;
};

export const accessI18nKeyByPlaceNumber = (placeIdHash: string): string => {
    const placeNumber: number = placeNumberByHash(placeIdHash);
    return `data.${placeNumber}.access`;
};

export const prTitleI18nKeyByPlaceNumber = (placeIdHash: string): string => {
    const placeNumber: number = placeNumberByHash(placeIdHash);
    return `data.${placeNumber}.pr.title`;
};

export const prTextI18nKeyByPlaceNumber = (placeIdHash: string): string => {
    const placeNumber: number = placeNumberByHash(placeIdHash);
    return `data.${placeNumber}.pr.text`;
};
