
import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "component-congestion-display",
  props: {
    display: Object as PropType<{
      state: string;
      text: string;
      countText: string;
      appearance: {
        backgroundColor: string;
        textColor: string;
      };
    }>,
  },
  computed: {
    state(): string {
      if (this.display === undefined) return "";
      return this.display.state;
    },
    title(): string {
      if (this.display === undefined) return "";
      return this.display.text;
    },
  },
});
