
import MixinPlace from "@/mixins/places-card-mixin";
import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { shortAccessI18nKeyByPlaceNumber } from "@/plugins/support";
// components
import ImageWithFallback from "@/components/image-with-fallback.vue";
import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";
import VIcon from "@/components/v/icon.vue";
import VButton from "@/components/v/button.vue";
import ForecastSection from "@/components/place/card-forecast/card-forecast-section.vue";

type Equipment = BackendPlace.Equipment;

export default MixinPlace.extend({
  name: "component-card-forecast",
  mixins: [MixinPlace],
  components: {
    ImageWithFallback,
    LabelSet,
    VacancyText,
    VIcon,
    VButton,
    ForecastSection,
  },
  props: {
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    hotSpring: {
      type: Object as () => Http.ResponseHotSpringValues,
      required: false,
    },
    forecastList: { type: Array as () => BackendVacancy.VacancyOfHour[], default: () => [] },
  },
  computed: {
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.currentGender;
    },
    accessI18nKey(): string {
      return shortAccessI18nKeyByPlaceNumber(this.place.placeIdHash);
    },
    isEnglish(): boolean {
      return this.$i18n.locale.includes("en");
    },
    isChinese(): boolean {
      return this.$i18n.locale.includes("zh");
    },
    cardLabel(): string {
      return `${this.place.name} ${this.$i18n.t(this.accessI18nKey)} ${this.vacancy.status.text}`;
    },
  },
});
