
import Vue from "vue";

export default Vue.extend({
  name: "component-card-forecast-square",
  props: {
    state: { type: String, default: undefined },
    isClosed: { type: Boolean, default: false },
    hour: { type: Number, required: true },
  },
  computed: {
    isGreen(): boolean {
      return this.state === "vacancy" && !this.isClosed;
    },
    isOrange(): boolean {
      return this.state === "crowded" && !this.isClosed;
    },
    isPink(): boolean {
      return (
        (this.state === "busy" ||
          this.state === "full" ||
          this.state === "waiting" ||
          this.state === "entryRestricted" ||
          this.state === "reserved") &&
        !this.isClosed
      );
    },
    isWhite(): boolean {
      return (
        this.state === undefined ||
        this.state === "" ||
        this.state === "unknown" ||
        this.state === "maintenance" ||
        this.state === "notReady" ||
        this.state === "waitingForServe" ||
        this.state === "backSoon"
      );
    },
    isGray(): boolean {
      return this.isClosed || this.state === "lo" || this.state === "closed";
    },
  },
});
