
import Vue from "vue";
export default Vue.extend({
  name: "close-page-button",
  props: {
    isAbsolute: {
      type: Boolean,
      default: false,
    },
  },
});
