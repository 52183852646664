import { AxiosResponse, AxiosError } from "axios";
import { $httpEnterpriseVacanAdapter } from "@/api/http";

import { Vacancy, Http, Place } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import { locale } from "@/plugins/i18n";

const ConnectionError = { message: "connection error", name: "" };

export function fetchPlaceListByWebsiteId(websiteId: string): Promise<Http.ResponseGetPlacesInWebsite | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get(`/v1/websites/${websiteId}`, { params: { namespace, language: locale } })
        .then((response: AxiosResponse) => (response.status === 204 ? undefined : response.data))
        .catch((error: AxiosError) => {
            Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export async function fetchPlaceDetailByPlaceIdHash(placeIdHash: string): Promise<Place.PlaceDetail> {
    return await $httpEnterpriseVacanAdapter
        .get(`/v1/places/${placeIdHash}`, { params: { language: locale, unixtime: Date.now() / 1000 } })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchResponseHotSpringValuesByPlaceIdHashList(
    placeIdHashList: string[],
): Promise<Http.ResponseHotSpringValues[]> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get(`/v1/places/${placeIdHashList.join(",")}/hotspring/settings`, { params: { namespace, language: locale } })
        .then((response: AxiosResponse) => (response.status === 204 ? undefined : response.data))
        .catch((error: AxiosError) => {
            Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchVacancyListByPlaceIdHashList(placeIdHashList: string[]): Promise<Vacancy.Vacancy[]> {
    const params: Record<string, string> = { language: locale };

    return $httpEnterpriseVacanAdapter
        .get(`/v1/places/${placeIdHashList.join(",")}/vacancies`, { params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

/** @remarks congestion forecast */
export function fetchWeeks(countryName = "jp"): Promise<Http.ResponseWeekdayList> {
    const unixtime = new Date().getTime() / 1000;
    const params = { unixtime };
    return $httpEnterpriseVacanAdapter
        .get(`/v1/weeks/${countryName}`, { params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => {
            Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}
export function fetchVacancyForecastByPlaceIdHashList(
    placeIdHashList: string[],
    selectedUnixtime: number,
): Promise<Http.ResponseForecast> {
    const params: Record<string, string | number> = { language: locale, unixtime: selectedUnixtime };
    return $httpEnterpriseVacanAdapter
        .get(`/v1/places/${placeIdHashList.join(",")}/forecast`, { params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => {
            Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}
