
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import {
  Vacancy as BackendVacancy,
  Place as BackendPlace,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import {
  fetchPlaceDetailByPlaceIdHash,
  fetchVacancyListByPlaceIdHashList,
  fetchResponseHotSpringValuesByPlaceIdHashList,
} from "@/api/enterprise-vacan.adapter.api";
// components
import PlacePanelImage from "@/components/place/panel/image.vue";
import PlacePanelSummary from "@/components/place/panel/summary.vue";
import PlacePanelBasic from "@/components/place/panel/basic.vue";
import PlacePanelMedia from "@/components/place/panel/media.vue";
import Questionnaire from "@/components/questionnaire.vue";
import ForecastDaysRow from "@/components/place/forecast-date-selector.vue";
import ForecastSection from "@/components/place/card-forecast/card-forecast-section.vue";
import PlacePanel from "@/components/place/panel/place-panel.vue";
import CongestionDisplayList from "@/components/place/container-congestion-display-list.vue";

type PlaceDetail = BackendPlace.PlaceDetail;
type Vacancy = BackendVacancy.Vacancy;
type HotSpringSetting = Http.ResponseHotSpringValues;

interface DataViewsPlaces {
  placeDetail: PlaceDetail | undefined;
  vacancy: Vacancy | undefined;
  hotSpringSetting: HotSpringSetting | undefined;
  timer: { fetchingVacancies: number | undefined };
  pageStatus: "ok" | "preparing" | "error";
}

export default Vue.extend({
  name: "views-single-place",
  components: {
    PlacePanelImage,
    PlacePanelSummary,
    PlacePanelBasic,
    PlacePanelMedia,
    Questionnaire,
    ForecastDaysRow,
    ForecastSection,
    PlacePanel,
    CongestionDisplayList,
  },
  props: {
    placeIdHash: { type: String, required: true, default: undefined },
    websiteId: { type: String, required: true, default: undefined },
    questionnaireUrl: { type: String, required: false, default: undefined },
  },
  data(): DataViewsPlaces {
    return {
      placeDetail: undefined,
      vacancy: undefined,
      hotSpringSetting: undefined,
      timer: { fetchingVacancies: undefined },
      pageStatus: "preparing",
    };
  },
  computed: {
    ...mapState(["weekdayList"]),
    forecastList(): BackendVacancy.VacancyOfHour {
      return this.$store.getters.getForecast(this.placeIdHash);
    },
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.currentGender;
    },
  },
  async created() {
    this.setViewType("forecast");
  },
  async mounted() {
    this.setup();
  },
  async destroyed() {
    await this.teardown();
  },
  methods: {
    ...mapMutations(["setViewType"]),
    async setup(): Promise<void> {
      this.pageStatus = "preparing";
      this.$store.dispatch("fetchWeekdayList");
      await fetchPlaceDetailByPlaceIdHash(this.placeIdHash)
        .then(async (placeDetail: PlaceDetail) => {
          this.$store.dispatch("updatePlace", placeDetail);
          this.placeDetail = placeDetail;
          this.pageStatus = "ok";
          await this.fetchHotSrpingSetting();
          await this.fetchVacancy();
          this.loop();
        })
        .catch(() => {
          this.pageStatus = "error";
        });
    },
    loop(): void {
      this.timer.fetchingVacancies = setInterval(async () => {
        this.$store.dispatch("fetchWeekdayList");
        await this.fetchVacancy().catch(() => {
          this.pageStatus = "error";
          this.teardown();
        });
      }, 30000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearInterval(this.timer.fetchingVacancies);
      }
    },
    async fetchVacancy(): Promise<void> {
      const vacancyList: Vacancy[] = await fetchVacancyListByPlaceIdHashList([this.placeIdHash]);
      const vacancy: Vacancy | undefined = vacancyList.shift();
      if (vacancy !== undefined) {
        this.vacancy = vacancy;
      }
    },
    async fetchHotSrpingSetting(): Promise<void> {
      const hotSpringSettingList: HotSpringSetting[] = await fetchResponseHotSpringValuesByPlaceIdHashList([
        this.placeIdHash,
      ]);
      const hotSpringSetting: HotSpringSetting | undefined = hotSpringSettingList.shift();
      if (hotSpringSetting !== undefined) {
        this.hotSpringSetting = hotSpringSetting;
      }
    },
  },
});
