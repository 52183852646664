
import Vue from "vue";

import VButton from "@/components/v/button.vue";
import PoweredByVacan from "@/components/v/powered-by-vacan.vue";

export default Vue.extend({
  name: "the-footer",
  components: {
    VButton,
    PoweredByVacan,
  },
  props: {
    showTerms: { type: Boolean, default: false },
    showIseCityLogo: { type: Boolean, default: false },
  },
  methods: {
    openContrastSetting() {
      this.$emit("open-contrast-setting");
    },
  },
});
