import { render, staticRenderFns } from "./powered-by-vacan.vue?vue&type=template&id=636b2fb4&scoped=true&"
var script = {}
import style0 from "./powered-by-vacan.vue?vue&type=style&index=0&id=636b2fb4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "636b2fb4",
  null
  
)

export default component.exports