
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { fetchVacancyListByPlaceIdHashList } from "@/api/enterprise-vacan.adapter.api";
import { fetchResponseHotSpringValuesByPlaceIdHashList } from "@/api/enterprise-vacan.adapter.api";
// components
import TheHeader from "@/components/the-header.vue";
import Questionnaire from "@/components/questionnaire.vue";
import PlaceContainer from "@/components/place/container.vue";
import ForecastDateSelector from "@/components/place/forecast-date-selector.vue";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type HotStringSetting = Http.ResponseHotSpringValues;

interface DataPlaceMultipleIndex {
  vacancyList: Vacancy[];
  timer: { fetchingVacancies: number | undefined };
  hotSpringSettingList: HotStringSetting[];
  fixDaysToTop: boolean;
}

type DisplayMode = "single" | "multiple" | "none";

export default Vue.extend({
  name: "views-multiple-places",
  components: {
    TheHeader,
    PlaceContainer,
    Questionnaire,
    ForecastDateSelector,
  },
  props: {
    placeList: { type: Array as () => Place[], default: () => [] },
    website: {
      type: Object as () => { id: string; name: string; questionnaireUrl?: string },
      default: (): { id: string; name: string; questionnaireUrl?: string } => ({
        id: "",
        name: "",
        questionnaireUrl: undefined,
      }),
    },
  },
  data(): DataPlaceMultipleIndex {
    return {
      vacancyList: [],
      timer: { fetchingVacancies: undefined },
      hotSpringSettingList: [],
      fixDaysToTop: false,
    };
  },
  computed: {
    ...mapState(["viewType", "selectedUnixtime", "weekdayList"]),
    forecastState(): { [placeIdHash: string]: BackendVacancy.VacancyOfHour[] } {
      return this.$store.getters.getForecastDictionary;
    },
    placeIdHashList(): string[] {
      return this.placeList.map((place: Place) => place.placeIdHash);
    },
    vacancyByPlaceIdHash(): (placeIdHash: string) => Vacancy | undefined {
      return (placeIdHash: string) => this.vacancyList.find((vacancy: Vacancy) => vacancy.placeIdHash === placeIdHash);
    },
    hasAtLeastOnePlace(): boolean {
      return this.placeList.length > 0 && this.vacancyList.length > 0;
    },
    displayMode(): DisplayMode {
      if (!this.hasAtLeastOnePlace) {
        return "none";
      }
      return this.placeList.length === 1 ? "single" : "multiple";
    },
  },
  async created() {
    await this.setup();
    this.loop();
  },
  destroyed() {
    this.teardown();
  },
  methods: {
    ...mapMutations(["setViewType", "setSelectedUnixtime"]),
    fixedDaysRow(payload: boolean) {
      this.fixDaysToTop = payload;
    },
    async setup(): Promise<void> {
      this.vacancyList = await fetchVacancyListByPlaceIdHashList(this.placeIdHashList);
      this.hotSpringSettingList = await fetchResponseHotSpringValuesByPlaceIdHashList(this.placeIdHashList);
      this.$store.dispatch("fetchWeekdayList");
    },
    loop(): void {
      this.timer.fetchingVacancies = setTimeout(async () => {
        this.setup();
        this.loop();
      }, 5000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearTimeout(this.timer.fetchingVacancies);
      }
    },
  },
});
