var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'square',
    {
      whiteBackground: _vm.isWhite,
      grayBackground: _vm.isGray,
      greenBackground: _vm.isGreen,
      orangeBackground: _vm.isOrange,
      pinkBackground: _vm.isPink,
    },
  ],attrs:{"data-id":_vm.hour}})
}
var staticRenderFns = []

export { render, staticRenderFns }