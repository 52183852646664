
import Vue from "vue";

import { fetchPlaceListByWebsiteId } from "@/api/enterprise-vacan.adapter.api";
import { Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import PlaceSingle from "@/components/place/single/index.vue";
import PlaceMultiple from "@/components/place/multiple/index.vue";
import { mapState } from "vuex";

interface DataViewsIndex {
  website: { id: string; name: string; questionnaireUrl?: string };
}

type DisplayMode = "single" | "multiple" | "none";

export default Vue.extend({
  name: "views-index",
  components: {
    PlaceSingle,
    PlaceMultiple,
  },
  data(): DataViewsIndex {
    return {
      website: { id: "", name: "", questionnaireUrl: "" },
    };
  },
  computed: {
    ...mapState(["placeList"]),
    hasAtLeastOnePlace(): boolean {
      return this.placeList.length > 0;
    },
    displayMode(): DisplayMode {
      if (!this.hasAtLeastOnePlace) {
        return "none";
      }
      return this.placeList.length === 1 ? "single" : "multiple";
    },
  },
  async created() {
    this.setup();
  },
  methods: {
    async setup(): Promise<void> {
      const websiteId: string | undefined = process.env.VUE_APP_WEBSITE_ID;
      if (websiteId === undefined) {
        return;
      }
      const placeResponse: Http.ResponseGetPlacesInWebsite | undefined = await fetchPlaceListByWebsiteId(websiteId);
      if (placeResponse === undefined) {
        return;
      }
      this.$store.dispatch("setPlaceList", placeResponse.placeList);
      this.website = {
        id: websiteId,
        name: placeResponse.websiteTitle.name,
        questionnaireUrl: placeResponse?.questionnaireUrl,
      };
    },
  },
});
