
import Vue from "vue";

import TheFooterFloating from "@/components/the-footer-floating.vue";
import TheFooter from "./components/the-footer.vue";
import ColorContrastSettingModal from "./components/color-contrast-setting-modal.vue";

export default Vue.extend({
  name: "app",
  components: {
    TheFooterFloating,
    TheFooter,
    ColorContrastSettingModal,
  },
  data() {
    return {
      contrastSetting: "normal",
      showContrastSetting: false,
    };
  },
  computed: {
    showIseCityLogo(): boolean {
      return ["Place"].includes(this.$route.name ?? "");
    },
  },
  mounted() {
    const previousContrastSetting = localStorage.getItem("contrast");
    this.contrastSetting = previousContrastSetting || "normal";
  },
  methods: {
    openContrastSettingModal() {
      this.showContrastSetting = true;
    },
    closeContrastSettingModal() {
      this.showContrastSetting = false;
      this.contrastSetting = localStorage.getItem("contrast") || "normal";
    },
  },
});
