
import Vue from "vue";

export default Vue.extend({
  name: "component-card-timeline-square",
  props: {
    hour: { type: Number, required: true },
    isCurrentHour: { type: Boolean, default: false },
    isFirst: { type: Boolean, default: false },
  },
  computed: {
    showHour(): string {
      if (this.isFirst) {
        // show 00:00
        return `${`0${this.hour}`.slice(-2)}:00`;
      } else {
        return this.hour % 3 === 0 ? String(this.hour) : "";
      }
    },
    showHourIs2Bit(): boolean {
      return !this.isFirst && this.showHour.length === 2;
    },
    drawLine(): string {
      if (this.isCurrentHour) {
        return "today";
      } else {
        return this.hour % 3 === 0 ? "long" : "short";
      }
    },
  },
});
