declare global {
    interface Document {
        documentMode?: unknown;
    }

    interface Window {
        MSInputMethodContext: unknown;
    }
}

function isIE11(): boolean {
    return !!window.MSInputMethodContext && !!window.document.documentMode;
}

export default {
    isIE11,
};
