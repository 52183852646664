
import Vue, { PropType } from "vue";
import { Place } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import "dayjs/locale/zh";
import "dayjs/locale/zh-tw";
import "dayjs/locale/zh-cn";
import i18n from "@/plugins/i18n";
dayjs.locale(i18n.locale.toLowerCase());

export default Vue.extend({
  name: "forecast-date-button",
  props: {
    display: { type: Object as PropType<Place.Weekday>, default: undefined },
    selected: Boolean,
  },
  computed: {
    isWorkday(): boolean {
      if (this.display === undefined) return true;
      return this.display.isWorkday;
    },
    dayOfTheWeek(): string {
      if (this.display === undefined) return "";
      return dayjs.unix(this.display.unixtime).format("ddd");
    },
    selecteDates(): string {
      if (this.display === undefined) return "";
      return dayjs.unix(this.display.unixtime).format(this.selected ? "M/D" : "D");
    },
  },
  methods: {
    btnClicked() {
      this.$emit("date-clicked", this.display.unixtime);
    },
  },
});
