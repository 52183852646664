import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Index from "../views/index.vue";
import Places from "../views/places.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    { path: "/", name: "Index", component: Index },
    { path: "/places/:placeIdHash", name: "Place", component: Places, props: true },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
