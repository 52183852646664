
import Vue from "vue";
import { mapState } from "vuex";
import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import PlaceCard from "@/components/place/card.vue";
import PlaceCardForecast from "@/components/place/card-forecast.vue";
import CongestionDisplayList from "@/components/place/container-congestion-display-list.vue";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type HotSpringSetting = Http.ResponseHotSpringValues;

export default Vue.extend({
  name: "place-container",
  components: {
    PlaceCard,
    PlaceCardForecast,
    CongestionDisplayList,
  },
  props: {
    placeList: { type: Array as () => Place[], required: true, default: (): Place[] => [] },
    vacancyList: { type: Array as () => Vacancy[], required: true, default: (): Vacancy[] => [] },
    hotSpringSettingList: {
      type: Array as () => HotSpringSetting[],
      required: true,
      default: (): HotSpringSetting[] => [],
    },
    forecastState: {
      type: Object as () => { [placeIdHash: string]: BackendVacancy.VacancyOfHour[] },
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(["viewType"]),
    vacancyByPlaceIdHash(): (placeIdHash: string) => Vacancy | undefined {
      return (placeIdHash: string) => this.vacancyList.find((vacancy: Vacancy) => vacancy.placeIdHash === placeIdHash);
    },
    hotSpringSettingByPlaceIdHash(): (placeIdHash: string) => HotSpringSetting | undefined {
      return (placeIdHash: string) =>
        this.hotSpringSettingList.find(
          (hotSpringSetting: HotSpringSetting) => hotSpringSetting.placeIdHash === placeIdHash,
        );
    },
    placeListForForecast(): Place[] {
      const forecastPlaces: string[] = process.env.VUE_APP_PLACE_ID_LIST_SHOW_FORECAST?.split(",") ?? [];
      return this.placeList.filter((place) => forecastPlaces.includes(place.placeIdHash));
    },
  },
});
