
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { ViewType } from "@/store/index";
import ie from "@/utils/ie11";
// components
import FormRadio from "@/components/v/radio.vue";

interface DataType {
  selectedRadio: ViewType;
  fixDaysToTop: boolean;
}

export default Vue.extend({
  name: "component-header-forecast",
  components: {
    FormRadio,
  },
  data(): DataType {
    return {
      selectedRadio: "realtime",
      fixDaysToTop: false,
    };
  },
  computed: {
    ...mapState(["viewType"]),
  },
  watch: {
    selectedRadio(newStatus: ViewType, oldStatus: ViewType) {
      if (newStatus === oldStatus) return;

      // GA4
      if (newStatus === "forecast") {
        this.$gtag.event("Weekly Forecast Button");
      } else {
        this.$gtag.event("Realtime Button");
      }

      this.setViewType(newStatus);
    },
  },
  async created() {
    this.selectedRadio = this.viewType !== undefined ? this.viewType : "realtime";
  },
  mounted() {
    this.listenListSroll();
  },
  beforeDestroy() {
    this.removeListSroll();
  },
  methods: {
    ...mapMutations(["setViewType"]),
    listenListSroll() {
      window.addEventListener("scroll", this.handleListScroll);
    },
    removeListSroll() {
      window.removeEventListener("scroll", this.handleListScroll);
    },
    handleListScroll() {
      if (ie.isIE11() && this.viewType === "forecast") {
        const position = window.pageYOffset;
        if (position >= 190) {
          this.fixDaysToTop = true;
        } else {
          this.fixDaysToTop = false;
        }
        this.$emit("fixed-days-row", this.fixDaysToTop);
      }
    },
  },
});
