
import Vue from "vue";
import { mapState } from "vuex";
import { Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import dayjs from "dayjs";
import ie from "@/utils/ie11";
// components
import HeaderForecastDateBtn from "@/components/place/header-forecast-date-btn.vue";

export default Vue.extend({
  name: "forecast-date-selector",
  components: {
    HeaderForecastDateBtn,
  },
  props: {
    weekdayList: { type: Array as () => Http.ResponseWeekdayList, default: () => [] },
    fixDaysToTop: Boolean,
  },
  computed: {
    ...mapState(["viewType", "selectedUnixtime"]),
    ie11(): boolean {
      return ie.isIE11();
    },
    selectedIndex(): number {
      const selectedTime = dayjs.unix(this.selectedUnixtime);

      return this.weekdayList.findIndex((week: Http.ResponseWeekday) => {
        // response BFF unixtime is not totally equal to frontend time
        const weekBtnTime = dayjs.unix(week.unixtime);
        return selectedTime.isSame(weekBtnTime, "date");
      });
    },
  },
  methods: {
    ifSelected(index: number) {
      return index === this.selectedIndex;
    },
    dateClicked(datetime: number) {
      this.$store.commit("setSelectedUnixtime", datetime);
    },
  },
});
