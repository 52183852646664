
import Vue from "vue";
//components
import CongestionDisplay from "@/components/place/container-congestion-display.vue";
import { Vacancy } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
interface DataType {
  congestionDisplayList: {
    state: Vacancy.VacancyState;
    text: string;
    countText: string;
    appearance: {
      backgroundColor: string;
      textColor: string;
    };
  }[];
}

export default Vue.extend({
  name: "component-container-congestion-display-list",
  components: {
    CongestionDisplay,
  },
  data(): DataType {
    return {
      congestionDisplayList: [
        {
          state: "vacancy",
          text: this.$i18n.t("Forecast.displayVacancyStatus.vacancy") as string,
          countText: "",
          appearance: {
            backgroundColor: "#ffffff",
            textColor: "$lime",
          },
        },
        {
          state: "crowded",
          text: this.$i18n.t("Forecast.displayVacancyStatus.crowded") as string,
          countText: "",
          appearance: {
            backgroundColor: "#ffffff",
            textColor: "$tiger",
          },
        },
        {
          state: "busy",
          text: this.$i18n.t("Forecast.displayVacancyStatus.busyFull") as string,
          countText: "",
          appearance: {
            backgroundColor: "#ffffff",
            textColor: "$coral",
          },
        },
        {
          state: "closed",
          text: this.$i18n.t("Forecast.displayVacancyStatus.closed") as string,
          countText: "",
          appearance: {
            backgroundColor: "#ffffff",
            textColor: "$gloom",
          },
        },
        {
          state: "unknown",
          text: this.$i18n.t("Forecast.displayVacancyStatus.noData") as string,
          countText: "",
          appearance: {
            backgroundColor: "#ffffff",
            textColor: "$text",
          },
        },
      ],
    };
  },
});
