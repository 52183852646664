import Vue from "vue";

import {
    Place as BackendPlace,
    Vacancy as BackendVacancy,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type VacancyStatus = BackendVacancy.VacancyState;

export default Vue.extend({
    name: "place-mixin",
    props: {
        place: {
            type: Object as () => Place,
            required: false,
            default() {
                return {
                    placeIdHash: "",
                    name: "",
                    floor: "",
                    imageUrlList: [],
                    openingHoursList: [],
                    equipmentList: [],
                    featureList: [],
                };
            },
        },
        vacancy: {
            type: Object as () => Vacancy,
            required: false,
            default() {
                return {
                    placeIdHash: "",
                    status: {
                        state: "closed" as VacancyStatus,
                        text: "-",
                        countText: "-",
                        appearance: {
                            textColor: "#969696",
                            backgroundColor: "#ffffff",
                        },
                    },
                    qticketStatus: {
                        isAcceptable: false,
                        useSizeLimit: false,
                    },
                };
            },
        },
    },
});
