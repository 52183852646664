
import Vue from "vue";
import { Vacancy } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
// components
import ForecastSquare from "@/components/place/card-forecast/card-forecast-square.vue";
import TimelineSquare from "@/components/place/card-forecast/card-timeline-square.vue";
import dayjs from "dayjs";

// isClosed = 営業時間外
type ForecastSquareData = {
  hour: number;
  state?: string;
  isClosed: boolean;
};
interface DataType {
  forecastSquareList: ForecastSquareData[];
  realtimeSquareList: ForecastSquareData[];
}

export default Vue.extend({
  name: "component-card-forecast-section",
  components: {
    ForecastSquare,
    TimelineSquare,
  },
  props: {
    forecastList: { type: Array as () => Vacancy.VacancyOfHour[], default: undefined },
    vacancy: { type: Object as () => Vacancy.Vacancy, default: undefined },
  },
  computed: {
    isToday(): boolean {
      return this.$store.getters.getIsToday;
    },
    isCurrentHour(): (hour: number) => boolean {
      return (hour: number) => this.isToday && hour === new Date().getHours();
    },
    forecastStateList(): ForecastSquareData[] {
      const forecastStateList: ForecastSquareData[] = [];

      for (let i = 0; i < 24; i++) {
        const forecast = this.forecastList?.find((forecast) => forecast.hour === i);
        forecastStateList.push({ hour: i, state: forecast?.state, isClosed: !forecast });
      }

      return forecastStateList;
    },
    realtimeStateList(): ForecastSquareData[] {
      const realtimeStateList: ForecastSquareData[] = [];
      const currentState = this.vacancy?.status.state;
      const currentHour = dayjs().hour();

      for (let i = 0; i < 24; i++) {
        const isCurrentHour = this.isToday && i === currentHour;

        realtimeStateList.push({
          hour: i,
          state: isCurrentHour ? currentState : undefined,
          isClosed: !this.forecastList?.find((forecast) => forecast.hour === i),
        });
      }

      return realtimeStateList;
    },
  },
  methods: {
    shiftIndex(index: number): number {
      return (index + 5) % 24;
    },
    shiftForecast(index: number): ForecastSquareData {
      const i = this.shiftIndex(index);
      return this.forecastStateList[i];
    },
    shiftRealtimeForecast(index: number): ForecastSquareData {
      const i = this.shiftIndex(index);
      return this.realtimeStateList[i];
    },
  },
});
