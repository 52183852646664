
import Vue from "vue";
import ie from "@/utils/ie11";

interface DataImageWithFallback {
  imgObj: { src: string; error: string; loading: string };
}

export default Vue.extend({
  name: "image-with-fallback",
  props: {
    src: { type: String, default: undefined },
    alt: { type: String, default: "" },
  },
  data(): DataImageWithFallback {
    return {
      imgObj: {
        src: this.src,
        error: require("@/assets/card-spinner.gif"),
        loading: require("@/assets/card-spinner.gif"),
      },
    };
  },
  computed: {
    ie11(): boolean {
      return ie.isIE11();
    },
  },
});
