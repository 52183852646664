
import Vue from "vue";

export default Vue.extend({
  name: "place-vacancy-text",
  props: {
    status: {
      type: String,
      required: false,
      default: "closed",
    },
    text: { type: String, required: false, default: "-" },
  },
});
