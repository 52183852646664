
import Vue from "vue";

import { Place as BackendPlace } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { accessI18nKeyByPlaceNumber } from "@/plugins/support";

type Equipment = BackendPlace.Equipment;

export default Vue.extend({
  name: "place-panel-basic",
  props: {
    placeIdHash: { type: String, required: true },
    tel: { type: String, required: false, default: undefined },
    address: { type: String, required: false, default: undefined },
    openingHoursList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    regularHolidayList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    equipmentList: { type: Array as () => Equipment[], default: (): Equipment[] => [] },
    remark: { type: String, required: false, default: undefined },
  },
  computed: {
    isSomeInformationExists(): boolean {
      return (
        this.showTel ||
        this.showAddress ||
        this.showAccess ||
        this.showOpeningHoursList ||
        this.showRegularHolidayList ||
        this.showEquipmentList ||
        this.showRemark
      );
    },
    accessI18nKey(): string {
      return accessI18nKeyByPlaceNumber(this.placeIdHash);
    },
    showTel(): boolean {
      return this.tel !== undefined && this.tel.length > 0;
    },
    showAddress(): boolean {
      return this.address !== undefined && this.address.length > 0;
    },
    showAccess(): boolean {
      return this.$t(this.accessI18nKey).length > 0;
    },
    showSchedules(): boolean {
      const idListCsv: string | undefined = process.env.VUE_APP_PLACE_ID_LIST_SHOW_SCHEDULES;
      if (idListCsv === undefined) {
        return false;
      }

      const idList: string[] = idListCsv.split(",");
      return idList.includes(this.placeIdHash);
    },
    showOpeningHoursList(): boolean {
      return this.showSchedules && this.openingHoursList.length > 0;
    },
    showRegularHolidayList(): boolean {
      return this.showSchedules && this.regularHolidayList.length > 0;
    },
    showEquipmentList(): boolean {
      return this.equipmentList.length > 0;
    },
    showRemark(): boolean {
      return this.remarkWithParsedUrl.length > 0;
    },
    displayEquipmentText(): string {
      if (!this.showEquipmentList) {
        return "";
      }
      return this.equipmentList
        .map((equipment: Equipment) => {
          switch (equipment.equipmentType) {
            case "power":
            case "wifi":
              return equipment.displayText;
            case "nonSmokingSeatCapacity":
            case "smokingSeatCapacity":
            case "totalSeatingCapacity":
              return this.$i18n.t(`place.basic.equipmentsItemTemp.${equipment.equipmentType}`, {
                count: equipment.displayText,
              });
            default:
              return undefined;
          }
        })
        .filter((text): text is string => text !== undefined)
        .join("、");
    },
    remarkWithParsedUrl(): string {
      if (this.remark === undefined) {
        return "";
      }
      const regexpUrl = /((https)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+))/g;
      return this.remark.replace(regexpUrl, "<a href='$1' target='_blank'>$1</a>");
    },
  },
});
