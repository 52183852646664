
import MixinPlace from "@/mixins/places-card-mixin";

import { Place as BackendPlace, Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { shortAccessI18nKeyByPlaceNumber } from "@/plugins/support";

import ImageWithFallback from "@/components/image-with-fallback.vue";
import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";
import VIcon from "@/components/v/icon.vue";
import VButton from "@/components/v/button.vue";

type Equipment = BackendPlace.Equipment;

export default MixinPlace.extend({
  name: "place-card-white-3-images",
  mixins: [MixinPlace],
  components: {
    ImageWithFallback,
    LabelSet,
    VacancyText,
    VIcon,
    VButton,
  },
  props: {
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    hotSpring: {
      type: Object as () => Http.ResponseHotSpringValues,
      required: false,
      //default: (): undefined => undefined,
    },
  },
  computed: {
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.currentGender;
    },
    accessI18nKey(): string {
      return shortAccessI18nKeyByPlaceNumber(this.place.placeIdHash);
    },
  },
});
