import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { Locales } from "locale";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);

    const messages: LocaleMessages = {};
    locales.keys().forEach((key: string) => {
        const matched = key.match(/([A-Za-z0-9-_,\s]+)\./i);
        if (matched && matched.length > 1) {
            // match file names
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

export const locale: string =
    (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || "en";

const supportLanguage = ["en", "ja", "zh-CN", "zh-TW"] as const; // correspond to file name of json files in locales directory
type SupportLanguage = (typeof supportLanguage)[number];

const interpreterAsSupportLanguage = (queryLanguage: string): SupportLanguage => {
    const supported: Locales = new Locales(supportLanguage);
    const locales: Locales = new Locales(queryLanguage);
    return locales.best(supported).toString() as SupportLanguage;
};

const interpretedLocale =
    locale === "zh" ? interpreterAsSupportLanguage("zh-TW") : interpreterAsSupportLanguage(locale);
// zh の時には zh-TW にするという仕様。この仕様でいいのか知らんけど。

export default new VueI18n({
    locale: interpretedLocale, // set from browser language
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages(), // load from file
});

document.documentElement.setAttribute("lang", interpretedLocale);
